<template>
  <div>
    <div v-if="showLoading" class="text-center" style="padding: 24px">
      <CSpinner/>
    </div>

    <CTabs @update:activeTab="onTabChange">
      <CTab id="tabOpen" title="Pending" active>
        <CDataTable
          :items="itemsOpen"
          :fields="fieldsOpen"
          :items-per-page="30"
          hover
          pagination>
          <template #show_details="{item, index}">
            <td class="py-2" style="white-space: nowrap">
              <CButton
                color="primary"
                variant="outline"
                square
                size="sm"
                @click="onClickFollowUp(item, index)">
                Follow up
              </CButton>
            </td>
          </template>
        </CDataTable>
      </CTab>
      <CTab id="tabResponded" title="Arsip">
        <CDataTable
          :items="itemsResponded"
          :fields="fieldsResponded"
          :items-per-page="30"
          hover
          pagination>
          <template #show_details="{item, index}">
            <td class="py-2" style="white-space: nowrap">
              <CButton
                color="primary"
                variant="outline"
                square
                size="sm"
                @click="onClickViewDetail(item, index)">
                View
              </CButton>
            </td>
            <td class="py-2" style="white-space: nowrap">
              <CButton
                color="warning"
                variant="outline"
                square
                size="sm"
                @click="onClickMoveToOpen(item, index)">
                Mark as Open
              </CButton>
            </td>
          </template>
        </CDataTable>
      </CTab>
    </CTabs>

    <CModal :show.sync="showDialogFollowUp" title="Follow-up Pendaftaran" hide-footer>
      <div style="padding: 10px">
        <CInput
          label="Nama"
          v-model="record.name"
          horizontal
        />
        <CInput
          label="Email"
          v-model="record.email"
          horizontal
        />
        <CInput
          label="No HP"
          v-model="record.mobile"
          horizontal
        />
        <CInput
          label="Kampus"
          v-model="record.campus"
          horizontal
        />
        <CInput
          label="Jurusan"
          v-model="record.major"
          horizontal
        />
        <CInput
          label="Angkatan"
          v-model="record.yearIn"
          horizontal
        />
        <CInput
          label="Pembimbing"
          v-model="record.mentor"
          horizontal
        />
        <CInput
          label="Kota domisili"
          v-model="record.city"
          horizontal
        />
        <CTextarea
          label="Catatan"
          v-model="record.note"
          horizontal
          rows="3"
        />
        <CSelect
          id="selectAction"
          :options="actionOptions"
          placeholder="Pilih keputusan"
        />
      </div>

      <div slot="footer">
        <CButton @click="closeDialog()">Batal</CButton>
        <CButton @click="confirmFollowUp()" :disabled="disableButtonOk">Simpan</CButton>
      </div>

    </CModal>

    <CModal :show.sync="showViewDetail" title="Data pendaftaran">
      <div style="padding: 10px">
        <table>
          <tr><td>Nama</td><td>{{record.name}}</td></tr>
          <tr><td>Email</td><td>{{record.email}}</td></tr>
          <tr><td>No HP</td><td>{{record.mobile}}</td></tr>
          <tr><td>Kampus</td><td>{{record.campus}}</td></tr>
          <tr><td>Jurusan</td><td>{{record.major}}</td></tr>
          <tr><td>Angkatan</td><td>{{record.yearIn}}</td></tr>
          <tr><td>Pembimbing</td><td>{{record.mentor}}</td></tr>
          <tr><td style="min-width:100px">Kota domisili</td><td>{{record.city}}</td></tr>
          <tr><td>Catatan</td><td>{{record.note}}</td></tr>
          <tr><td>Keputusan</td><td>{{record.stat}}</td></tr>
        </table>
      </div>
    </CModal>

  </div>
</template>

<script>
  import api from "../../api/api"

  const fieldsOpen = [
    { key: 'registerTimeFormatted', label: 'Waktu daftar', _style:'min-width:50px' },
    { key: 'name', label: 'Nama', _style:'min-width:100px' },
    { key: 'campus', label: 'Kampus', _style:'min-width:100px' },
    { key: 'major', label: 'Jurusan', _style:'min-width:70px;' },
    { key: 'yearIn', label: 'Angkatan', _style:'min-width:70px;' },
    { key: 'mentor', label: 'Pembimbing', _style:'min-width:100px;' },
    { key: 'stat', label: 'Status', _style:'min-width:70px;' },
    { 
      key: 'show_details', 
      label: '', 
      _style: 'width:1%',
      sorter: false, 
      filter: false
    }
  ]

  const fieldsResponded = [
    { key: 'registerTimeFormatted', label: 'Waktu daftar', _style:'min-width:50px' },
    { key: 'name', label: 'Nama', _style:'min-width:100px' },
    { key: 'campus', label: 'Kampus', _style:'min-width:100px' },
    { key: 'major', label: 'Jurusan', _style:'min-width:70px;' },
    { key: 'yearIn', label: 'Angkatan', _style:'min-width:70px;' },
    { key: 'mentor', label: 'Pembimbing', _style:'min-width:100px;' },
    { key: 'stat', label: 'Status', _style:'min-width:70px;' },
    { key: 'approverName', label: 'Approver', _style:'min-width:70px;' },
    { 
      key: 'show_details', 
      label: '', 
      _style: 'width:1%',
      sorter: false, 
      filter: false
    }
  ]

  export default {
    data () {
      return {
        showLoading: false,
        itemsOpen: [],
        itemsResponded: [],
        fieldsOpen,
        fieldsResponded,
        details: [],

        showDialogFollowUp: false,
        actionOptions: ["Terima sebagai anggota", "Tolak!"],

        showViewDetail: false,
        record: '',
        user: '',
      }
    },

    beforeDestroy () {
      this.$root.$off('dialogFollowUpCancel')
      this.$root.$off('dialogFollowUpOK')
    },

    async mounted () {
      this.$root.$on('dialogFollowUpCancel', () => {
        this.showDialogFollowUp = false
      })
      this.$root.$on('dialogFollowUpOK', () => {
        this.showDialogFollowUp = false
        this.listInquiryOpen()
      })
    },

    async created () {
      var idToken = localStorage.getItem('idToken')

      if(idToken===null || idToken===undefined || idToken==='undefined')
        this.$router.replace('/login', () => {})
      else{
        this.user = JSON.parse(localStorage.getItem('user'))
        this.listSignup()
      }
    },

    methods: {
      onTabChange(tabNo){
        //if(tabNo===0)
      },

      async listSignup () {
        this.itemsOpen = []
        this.itemsResponded = []

        this.showLoading = true
        let apiRes = await api.listSignup()
        this.showLoading = false

        if (apiRes.result === 'OK') {
          this.itemsOpen = apiRes.openItems
          this.itemsResponded = apiRes.closeItems
        } 
        else {
          console.log("listSignup ERR: " + apiRes.err)
          this.$router.replace('/login', () => {})
        }
      },

      async updateSignupStat (id, stat, note) {
        this.showLoading = true
        let apiRes = await api.updateSignupStat(id, this.user.id, stat, note)
        this.showLoading = false
        if (apiRes.result === 'OK') {
          this.listSignup()
        } 
        else {
          console.log("updateSignupStat ERR: " + apiRes.err)
          this.$router.replace('/login', () => {})
        }
      },

      onClickFollowUp (item, index) {
        this.record = item
        this.showDialogFollowUp = true
      },

      onClickViewDetail (item){
        this.record = item
        this.showViewDetail = true
      },

      onClickMoveToOpen (item) {
        this.$confirm("Pindahkan arsip ke pending?").then(() => {
          this.updateSignupStat(item.id, "OPEN", " ")
        });
      },

      closeDialog () {
        this.showDialogFollowUp = false
      },

      async confirmFollowUp () {
        var action = ''
        var e1 = document.getElementById("selectAction")

        if(e1.selectedIndex===0)
          this.$alert("Mohon pilih keputusan: Terima atau Tolak?")
        else if(e1.selectedIndex===2 && (this.record.note===undefined || this.record.note.trim()===''))
          this.$alert("Bila keputusan adalah Tolak, mohon beri catatan.")
        else{
          switch(e1.selectedIndex) {
            case 1: action = "ACCEPTED"; break;
            case 2: action = "REJECTED"; break;
          }
          this.showDialogFollowUp = false

          if(action==="REJECTED"){
            this.updateSignupStat(this.record.id, action, this.record.note)
          }
          else{
            if(this.record.note===undefined || this.record.note==='') this.record.note = ' '

            this.showLoading = true
            let apiRes = await api.approveSignup(this.record.id, this.user.id, this.record)
            this.showLoading = false

            if (apiRes.result === 'OK') {
              if(apiRes.status === "NEW")
                this.$alert("Pendaftaran anggota baru OK!")
              else if(apiRes.status === "DUPLICATE")
                this.$alert("Sudah ada anggota dengan email yang sama!")

              this.listSignup()
            } 
            else {
              console.log("approveSignup ERR: " + apiRes.err)
              this.$router.replace('/login', () => {})
            }
          }
        }
      },
    },

    computed: {
      disableButtonOk () {
        return this.record.note && this.record.note.length===0
      }
    },
  }
</script>
